<template>
  <div class="match-table">
    <div class="success">
      <p>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-success-tianchong"></use>
        </svg>
        <span>
          {{props.dataList.successList.length}} Successful matches
        </span>
      </p>
      <artmisads-table
        :bordered="true"
        :columns="columnsSuccess"
        row-key="id"
        :change="onChangeSuccessPage"
        rowClassName="table-item"
        :data-source="props.dataList.successList"
        :scroll="{ y: 231 }"
        :pagination="{
          current: successPage,
          total: props.dataList.successList.length,
          defaultPageSize: 10,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.name === 'commission'">
            <span :class="record.status === 'active' ? 'active-commission' : 'inactive-commission'">{{`${Number(record.commission).toFixed(1)}%`}}</span>
          </template>
          <template v-if="column.name === 'beforeStatus'">
            <showStatus :isActive="record.beforeStatus === 'active' || record.beforeStatus === 'Active'" :noBorder="true"/>
          </template>
          <template v-if="column.name === 'afterStatus'">
            <showStatus :isActive="record.afterStatus === 'active' || record.afterStatus === 'Active'" :noBorder="true"/>
          </template>
        </template>
      </artmisads-table>
    </div>
    <div class="operation-btn">
      <artmisads-button @click="onBack">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-zuo-cu"></use>
        </svg>
        Back
      </artmisads-button>
      <div class="update">
        <artmisads-button type="dashed" @click="onCancel">Cancel</artmisads-button>
        <artmisads-button v-if="props.dataList.successList.length && !loading" type="primary" @click="onUpdate">Update</artmisads-button>
        <artmisads-button v-else disabled>Update</artmisads-button>
      </div>
      
    </div>
    <div class="fail" v-if="props.dataList.failList.length">
      <p>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-a-error-tianchong5"></use>
        </svg>
        <span>{{props.dataList.failList.length}} Failed matches</span>
      </p>
      <artmisads-table
        bordered
        :columns="columnsFail"
        row-key="id"
        rowClassName="table-item"
        :data-source="props.dataList.failList"
        :change="onChangeFailPage"
        :scroll="{ y: 275 }"
        :pagination="{
          total: props.dataList.failList.length,
          defaultPageSize: 10,
          current: failPage,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.name === 'commission'">
            <span :class="record.status === 'active' || record.status === 'Active' ? 'active-commission' : 'inactive-commission'">{{`${Number(record.commission).toFixed(1)}%`}}</span>
          </template>
          <template v-if="column.name === 'status'">
            <!-- <showStatus :isActive="record.status === 'active' || record.status === 'Active'" :noBorder="true"/> -->
            {{record.status === 'active' || record.status === 'Active' ? 'Active' : 'Inactive'}}
          </template>
        </template>
      </artmisads-table>
    </div>
  </div>
</template>
<script lang="jsx" setup>
  import { message } from 'ant-design-vue';
  import { ref } from 'vue';

  import showStatus from '../../Brands/components/showStatus.vue';
  import { productsApi } from '../../../../server/index'

  const successPage = ref(1);
  const failPage = ref(1);
  const loading = ref(false);

  const props = defineProps([
    'dataList', 'setDataList', 'setShowExample', 'setShowUpdateProductModal', 'getProductList', 'onCancel'
  ]);

  const onChangeSuccessPage = ({ current }) => {
    successPage.value = current;
  }

  const onChangeFailPage = ({ current }) => {
    failPage.value = current;
  }

  const onUpdate = async () => {
    loading.value = true;
    let params = [...props.dataList.successList];
    params = params.map(item => {
      item.commission = item.afterCommissionStr;
      item.status = item.afterStatus;
      return item;
    })
    let res = await productsApi.doUpdateCommissionOrStatusCsv(params);
    const { success, error } = res;
    if (success) {
      message.success('Success');
      props.getProductList();
      props.onCancel();
    }
    loading.value = false;
  }

  const onBack = () => {
    props.setDataList({
      failList: [],
      successList: []
    });
    props.setShowExample(true);
  }

  const columnsFail = ref([
    {
      title: 'ASIN',
      dataIndex: 'asin',
    },
    {
      title: 'Amazon Seller Name',
      dataIndex: 'amazonName',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status'
    },
  ])

  const columnsSuccess = ref([
    {
      title: 'Product',
      dataIndex: 'title',
      width: '30%',
      customRender: ({ text, record }) => {
        return (
          <div class="product-item">
            <img src={record.imageUrl}/>
            <div class="text">
              <span class="title">{text}</span>
              <span class="asin">{record.asin}</span>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Amazon Seller Name',
      dataIndex: 'amazonName',
      width: '20%',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      width: '20%',
      children: [
        {
          title: 'Before',
          dataIndex: 'beforeCommissionStr',
          customRender: ({ text, record }) => `${text}%`
        },
        {
          title: 'After',
          dataIndex: 'afterCommissionStr',
          customRender: ({ text, record }) => `${text}%`
        },
      ]
    },
    {
      title: 'Status',
      dataIndex: 'id',
      width: '20%',
      children: [
        {
          title: 'Before',
          dataIndex: 'beforeStatus',
          name: 'beforeStatus'
        },
        {
          title: 'After',
          dataIndex: 'afterStatus',
          name: 'afterStatus'
        },
      ]
    },
  ])

</script>

<style lang="less" scoped>
  .match-table {
    padding-bottom: 12px;
    .success,
    .fail {
      > p {
        display: flex;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-bottom: 12px;
        font-weight: 500;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        > span {
          align-self: center;
          font-size: 16px;
        }
      }
    }
    
    .success {
      padding: 8px 0 2px;
      > p {
        :deep(svg) {
          fill: var(--green-3);
        }
      }
    }
    .fail {
      margin: 0 -24px -24px;
      border-radius: 0 0 8px 8px;
      padding: 24px 24px 8px 24px;
      background-color: var(--bg-gray-1);
      border-top: 1px solid var(--border-4);
      > p {
        svg {
          fill: var(--red-1);
        }
      }
    }
    .operation-btn {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      > button {
        display: flex;
        svg {
          align-self: center;
          width: 18px;
          height: 18px;
          fill: var(--dark-2);
          margin-right: 8px;
        }
        :deep(span) {
          align-self: center;
        }
      }
      .update {
        > button {
          margin-left: 8px;
        }
      }
    }
    :deep(.ant-table) {
      .ant-table-cell {
        word-break: break-word;
        padding: 16px;
        line-height: 20px;
        border-color:  var(--border-4) !important;
      }
      .ant-table-thead {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        .ant-table-cell {
        border-color:  var(--border-1) !important;
        border-inline-end-color: var(--border-1) !important;
        }
      }
      .ant-table-container {
        border-color:  var(--border-4) !important;
      }
      table {
        border-top: none !important;
        border-color:  var(--border-4) !important;
      }
      .inactive-commission {
        color: var(--dark-4);
      }
      .status-text {
        text-transform: capitalize;
      }
      .product-item {
        display: flex;
        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
          object-fit: contain;
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          justify-content: center;
          .title {
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .asin {
            margin-top: 4px;
          }
        }
      }
    }
  }
  
</style>