<template>
  <a-spin :spinning="loading">
    <div class="example-table">
      <div class="notice">
        <p>Upload a CSV file with the following columns, which is specifically designed to update the commission and status of the products existing within the current list, rather than to introduce any new products.</p>
        <p>It is recommended that you use the "Products→Export Products" function to export the information, and then upload it after making modifications, so as to update it correctly.</p>
        <p>Please note that ArtemisAds only accepts commission rate settings with a minimum unit of 0.5%. We will automatically adjust any inappropriate rate settings.</p>
      </div>
      <div class="down-example">
        <artmisads-select-button @click="exportDownloadProductTemplate">
          <svg class="icon icon-daochu1" aria-hidden="true">
            <use xlink:href="#icon-daochu1"></use>
          </svg>
          Download the Template
        </artmisads-select-button>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="table">
            <artmisads-table
              :columns="columns"
              row-key="id"
              :data-source="exampleDataList"
              :pagination="false"
              :bordered="true"
            >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name === 'status' && (record.status === 'inactive' || record.status === 'active')">
                {{record.status === 'inactive' ? 'Inactive' : 'Active'}}
                <!-- <showStatus :isActive="record.status === 'active'" :noBorder="true"/> -->
              </template>
            </template>
            </artmisads-table>
          </div>
          <div class="file-name" v-if="fileName">
            <div>
              <svg class="icon icon-biaoge" aria-hidden="true">
                <use xlink:href="#icon-biaoge"></use>
              </svg>
              <span class="name">{{fileName}}</span>
              <svg class="icon close-cu" aria-hidden="true" @click="clearFile">
                <use xlink:href="#icon-close-cu"></use>
              </svg>
            </div>
          </div>
          <a-upload
            name="avatar"
            list-type="picture-card"
            :show-upload-list="false"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            :before-upload="beforeUpload"
            @change="handleChange"
            v-else
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <svg class="icon shangchuan2" aria-hidden="true">
              <use xlink:href="#icon-jiahao-cu"></use>
            </svg>
            Choose a file...
          </a-upload>
        </div>
        <div class="content-right">
          <div class="description">
            <span class="line"></span>
            <span class="text">Description</span>
          </div>
          <div class="required">
            <span class="line"></span>
            <span class="text">Required</span>
          </div>
          <div class="example">
            <span class="line"></span>
            <span class="text">Example</span>
          </div>
        </div>
      </div>
      <div class="next-btn">
        <artmisads-button type="primary" @click="toNext" v-if="dataList.failList.length || dataList.successList.length">
          Next
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-you-cu"></use>
          </svg>
        </artmisads-button>
        <artmisads-button type="primary" v-else disabled>Next</artmisads-button>
      </div>
    </div>
  </a-spin>
</template>
<script setup>
  import { DeleteOutlined } from "@ant-design/icons-vue";
  import { message } from 'ant-design-vue';
  import { ref } from 'vue';

  import showStatus from '../../Brands/components/showStatus.vue';
  import { productsApi } from '../../../../server/index'

  const fileName = ref('');
  const loading = ref(false);
  const props = defineProps([
    'setDataList', 'setShowExample', 'dataList'
  ]);

  const clearFile = () => {
    fileName.value = '';
    props.setDataList({ successList: [], failList: [] });
  }

  const exampleDataList = [
    {
      id: 1,
      asin: 'Ensure the ASINs are associated with your active brands on the Artemis Ads platform.',
      amazonSellerAccount: 'The same ASIN may exist under different Amazon Seller Names. This column can ensure that you update it correctly. The Amazon Seller Name can be found under Setting → Connections. Please make sure they are consistent.',
      commission: ' Specify the commission rate that affiliates will see. Format it as a percentage (e.g., 20%).',
      status: 'Indicate the status of each product to determine if affiliates can view and promote it. Use one of the following statuses: Active or Inactive.'
    },
    {
      id: 2,
      asin: 'Yes',
      amazonSellerAccount: 'Yes',
      commission: 'No',
      status: 'No',
    },
    {
      id: 3,
      asin: 'B08QRFZ5VV',
      amazonSellerAccount: 'Seller Name',
      commission: '20%',
      status: 'active',
    },
    {
      id: 4,
      asin: 'B05QRFZ8VV',
      amazonSellerAccount: 'Seller Name',
      commission: '20%',
      status: 'active',
    },
    {
      id: 5,
      asin: 'B07HHGS22J',
      amazonSellerAccount: 'Seller Name',
      commission: '15%',
      status: 'active',
    },
    {
      id: 6,
      asin: 'B09JKDAWU1',
      amazonSellerAccount: 'Seller Name',
      commission: '20%',
      status: 'active',
    },
  ];
  const columns = [
    {
      title: 'ASIN',
      dataIndex: 'asin',
      width: '17%'
    },
    {
      title: 'Amazon Seller Name',
      dataIndex: 'amazonSellerAccount',
      width: '39%'
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      width: '19%'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status',
      width: '25%'
    }
  ];

  const exportDownloadProductTemplate = () => {
    const finalParams = {
      qs: {},
      fileName: 'template.csv'
    }
    productsApi.exportDownloadProductTemplate(finalParams);
  }

  const handleChange = async ({file}) => {
    const { type, originFileObj, percent, name } = file;
    let form = new FormData();
		form.append('file', originFileObj);
    if (percent === 100) {
      if (type !== 'text/csv') {
        return message.error('Please upload a csv file.');
      } else {
        loading.value = true;
        let res = await productsApi.preCommissionCsv(form);
        const { success, error, data } = res;
        if (success) {
          const successList = data.successList || [];
          const failList = data.failList || [];
          if (!successList.length && !failList.length) {
            message.warning('No matching data found.');
          } else {
            message.success('Success');
            const dataList = { successList, failList };
            props.setDataList(dataList);
            fileName.value = name;
          }
          
        }
        loading.value = false;
      }
    }
  }

  const toNext = () => {
    props.setShowExample(false);
  }

</script>

<style lang="less" scoped>
  .example-table {
    .notice {
      padding-right: 160px;
      > p {
        letter-spacing: 0.4px;
        line-height: 20px;
        color: var(--dark-2);
        padding-top: 8px;
        &:last-child {
          padding-top: 10px;
        }
      }
    }
    
    .content {
      padding: 12px 0 24px ;
      display: flex;
      :deep(.ant-table-wrapper) {
        .ant-table-cell {
          padding: 8px;
          line-height: 20px;
          word-break: break-word;
          border-color:  var(--border-4) !important;
        }
        .ant-table-thead {
          .ant-table-cell {
          border-bottom-color:  var(--border-1) !important;
          }
        }
        .ant-table-container {
          border-color:  var(--border-4) !important;
        }
        table {
          border-top: none !important;
          border-color:  var(--border-4) !important;
        }
      }
    }
    .content-right {
      margin-left: 8px;
      margin-top: 40px;
      .description,
      .required,
      .example {
        display: flex;
        .line {
          width: 2px;
          height: 100%;
        }
        .text {
          font-weight: 500;
          line-height: 20px;
          padding-left: 8px;
          padding-right: 8px;
          height: 20px;
          align-self: center;
          border-radius: 4px;
          margin-left: 16px;
        }
      }
      .description {
        height: 112px;
        .line {
          background-color: var(--primary-bg);
        }
        .text {
          color: var(--primary-text);
          background-color: var(--table-cell-bg-3);
          border: 1px solid var(--primary-border-3);
        }
      }
      .required {
        height: 34px;
        margin-top: 4px;
        .line {
          background-color: var(--yellow-5);
        }
        .text {
          color: var(--yellow-4);
          background-color: var(--table-cell-bg-2);
          border: 1px solid var(--yellow-5);
        }
      }
      .example {
        height: 144px;
        margin-top: 4px;
        .line {
          background-color: var(--green-4);
        }
        .text {
          color: var(--green-1);
          background-color: var(--table-cell-bg-1);
          border: 1px solid var(--green-2);
        }
      }
    }
    .table {
      width: 1020px;
    }
    .down-example {
      margin-top: 12px;
      button {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
      }
    }
    .content-left {
      .file-name {
        display: flex;
        width: 100%;
        height: 60px;
        cursor: pointer;
        background: var(--big-bg-1);
        border-radius: 8px;
        color: var(--dark-2);
        margin-top: 8px;
        &:hover {
          background: var( --bg-gray-2);
        }
        > div {
          display: flex;
          height: 28px;
          padding: 0 24px;
          border-radius: 8px;
          align-self: center;
          // &:hover {
          //   background-color: var(--bg-gray-4);
          // }
          .icon-biaoge {
            width: 24px;
            height: 24px;
            fill: var( --primary-text);
            align-self: center;
          }
          .name {
            color: var( --primary-text);
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.4px;
            line-height: 22px;
            margin-left: 8px;
            margin-right: 24px;
            align-self: center;
          }
          .close-cu {
            cursor: pointer;
            width: 24px;
            height: 24px;
            padding: 4px;
            align-self: center;
            fill: var(--dark-3);
            &:hover {
              fill: var(--dark-1);
            }
          }
        }
        
      }
      :deep(.ant-upload-wrapper){
        margin-top: 16px;
        .ant-upload.ant-upload-select {
          width: 100%;
          height: 60px;
          color: var(--dark-2);
          background: var(--big-bg-1);
          border: 1px dashed var(--border-1);
          border-radius: 8px;
          font-size: var(--font-size-h-l);
          font-weight: 500;
          &:hover {
            background: var( --bg-gray-2);
            border: 1px dashed var(--bg-gray-7);
          }
          svg {
            width: 24px;
            height: 24px;
            fill: var(--dark-2);
            margin-right: 8px;
          }
        }
      }
    }
    .next-btn {
      border-top: 1px solid var(--border-4);
      padding: 10px 0 0 0;
      display: flex;
      justify-content: right;
      // > button {
      //   border: 2px solid var(--primary);
      //   box-shadow: var(--section-box-shadow-2);
      //   background-color: var(--color-white) !important;
      //   color: var(--primary);
      //   svg {
      //     fill: var(--primary);
      //     margin-left: 8px;
      //   }
      // }
    }
  }
</style>