<!--
 * @Author: fang.diao
 * @Date: 2024-12-11 17:20:20
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-25 15:46:31
 * @FilePath: /web_artemisads/src/pages/seller/product/fileBar/index.vue
 * @Description: 
-->
<template>
  <div class="file-bar" ref="fileBarRef">
    <div class="content-wrap">
      <div class="content">
        <artmisads-button-special @click="() => setShowUpdateProductDealsModal(true)">
          <svg class="icon shangchuan2" aria-hidden="true">
            <use xlink:href="#icon-shangchuan2"></use>
          </svg>
          <span class="btn-text">Manage Deals</span>
        </artmisads-button-special>
        <div class="right">
          <a-dropdown :getPopupContainer="() => fileBarRef">
            <template #overlay>
              <artmisads-dropdown-menu @click="handleMenuClick">
                <a-menu-item key="all">
                  <div class="actions-item">
                    <span>Export All Products</span>
                    <span v-if="exportsNum.allProductCount" class="num">{{exportsNum.allProductCount}}</span>
                  </div>
                </a-menu-item>
                <a-menu-item key="active">
                  <div class="actions-item">
                    <span>Export All Active Products</span>
                    <span v-if="exportsNum.activeProductCount" class="num">{{exportsNum.activeProductCount}}</span>
                  </div>
                </a-menu-item>
                <a-menu-item key="inactive">
                  <div class="actions-item">
                    <span>Export All Inactive Products</span>
                    <span v-if="exportsNum.inActiveProductCount" class="num">{{exportsNum.inActiveProductCount}}</span>
                  </div>
                </a-menu-item>
                <a-menu-item key="filter">
                  <div class="actions-item">
                    <span>Export with Filters</span>
                    <span v-if="exportsNum.filterProductCount" class="num">{{exportsNum.filterProductCount}}</span>
                  </div>
                </a-menu-item>
              </artmisads-dropdown-menu>
            </template>
            <artmisads-button-special>
              <svg class="icon daochu" aria-hidden="true">
                <use xlink:href="#icon-daochu"></use>
              </svg>
              <span class="btn-text">Export Products</span>
              <svg class="icon artmisads-select-down-icon" aria-hidden="true">
                <use xlink:href="#icon-jiantou-xia-cu"></use>
              </svg>
            </artmisads-button-special>
          </a-dropdown>
          <artmisads-button-special @click="() => setShowUpdateProductModal(true)">
            <svg class="icon shangchuan2" aria-hidden="true">
              <use xlink:href="#icon-shangchuan2"></use>
            </svg>
            <span class="btn-text">Bulk Update Products Via CSV</span>
          </artmisads-button-special>
        </div>
      </div>
    </div>
    <div class="activate-brand" ref="activateBrand">
      <artmisads-modal
        v-model:open="showUpdateProductModal"
        title="Bulk Update Products Via CSV"
        :width="1200"
        :getContainer="() => fileBarRef"
        :destroyOnClose="true"
        :maskClosable="false"
        :footer="null"
        :onCancel="onCancel"
      >
        <div>
          <exampleTable
            v-if="showExample"
            :setDataList="setDataList"
            :dataList="dataList"
            :setShowExample="setShowExample"
          />
          <failSuccessList
            v-else
            :dataList="dataList"
            :showExample="showExample"
            :setDataList="setDataList"
            :setShowUpdateProductModal="setShowUpdateProductModal"
            :getProductList="getProductList"
            :setShowExample="setShowExample"
            :onCancel="onCancel"
          />
        </div>
      </artmisads-modal>
      <artmisads-modal
        v-model:open="showUpdateProductDealsModal"
        title="Update Products Deals"
        :width="1200"
        :getContainer="() => fileBarRef"
        :destroyOnClose="true"
        :maskClosable="false"
        :footer="null"
        :onCancel="onDealsCancel"
      >
        <div>
          <uploadProductDeals
            v-if="showDealsResultModal"
            :setDataList="setDataList"
            :dataList="dataList"
            :setShowExample="setShowDealsResultModal"
          />
          <productDealsResult
            v-else
            :dataList="dataList"
            :showExample="showDealsResultModal"
            :setDataList="setDataList"
            :setShowUpdateProductModal="setShowUpdateProductDealsModal"
            :getProductList="getProductList"
            :setShowExample="setShowDealsResultModal"
            :onCancel="onDealsCancel"
          />
        </div>
      </artmisads-modal>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref } from 'vue';
  import exampleTable from './exampleTable.vue';
  import failSuccessList from './failSuccessList.vue';
  import uploadProductDeals from './uploadProductDeals.vue';
  import productDealsResult from './productDealsResult.vue';
  import { productsApi } from '../../../../server/index'

  const showUpdateProductModal = ref(false);
  const showExample = ref(true);

  const showUpdateProductDealsModal = ref(false);
  const showDealsResultModal = ref(true);

  const dataList = ref({
    failList: [],
    successList: []
  });
  const fileBarRef = ref(null);
  const props = defineProps([ 'params', 'getProductList', 'exportsNum']);

  const setShowUpdateProductModal = status => {
    showUpdateProductModal.value = status;
  }

  const setShowExample = status => {
    showExample.value = status;
  }
  
  const setShowUpdateProductDealsModal = status => {
    showUpdateProductDealsModal.value = status;
  }

  const setShowDealsResultModal = status => {
    showDealsResultModal.value = status;
  }

  const onDealsCancel = () => {
    showUpdateProductDealsModal.value = false;
    dataList.value = {
      failList: [],
      successList: []
    };
    showDealsResultModal.value = true;
  }

  const setDataList = data => {
    dataList.value = data;
  }

  const onCancel = () => {
    showUpdateProductModal.value = false;
    dataList.value = {
      failList: [],
      successList: []
    };
    showExample.value = true;
  }

  const handleMenuClick = async ({ key }) => {
    let exportParams = { exportType: key }, newExportParams = {};
    if (key === 'filter') {
      exportParams = {...props.params, ...exportParams };
    }
    for(let item in exportParams) {
      if (exportParams[item]) {
        newExportParams[item] = exportParams[item];
      }
    }
    
    const finalParams = {
      qs: newExportParams,
      fileName: 'Products.csv'
    }
    productsApi.exportProduct(finalParams);
  }

  const checkFromDashboard = () => {
    const artemisAds_dashboard_click_product_deal = localStorage.getItem('artemisAds_dashboard_click_product_deal');
    if (artemisAds_dashboard_click_product_deal === '1') {
      setShowUpdateProductModal(true);
      localStorage.setItem('artemisAds_dashboard_click_product_deal', '');
    }
  }

  onMounted(() => {
    checkFromDashboard();
  })


</script>
<style lang="less" scoped>
  .file-bar {
    display: flex;
    justify-content: right;
    padding: 14px 16px 0 16px;
    background-color: var(--color-white);
    .content-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 17px;
      border-bottom: 1px solid var(--border-4);
      > h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .content {
      width: 100%;
      display: flex;
      button {
        cursor: pointer;
        display: flex;
        .shangchuan2 {
          width: 20px;
          height: 20px;
          fill: var(--dark-1);
          margin-right: 8px;
        }
        .jiantou {
          width: 16px;
          height: 16px;
          fill: var(--dark-3);
          margin-left: 8px;
        }
        .daochu {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          fill: var(--dark-1);
        }
        > svg {
          align-self: center;
        }
        > span {
          align-self: center;
        }
        .btn-text {
          align-self: center;
          font-weight: 500;
          line-height: 20px;
          margin-right: 8px;
        }
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: right;
        > button {
          margin-left: 16px;
        }
      }
    }
    
  }
</style>
