<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :row-selection="{
        selectedRowKeys: props.selectedRowKeys,
        onChange: props.onSelectChange,
      }"
      :columns="columns"
      row-key="id"
      :data-source="props.dataSource"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true,
      }"
      :loading="props.loading"
      :change="onChangePage"
      rowClassName="table-item"
      :scroll="{ x: 1200 }"
      :noData="props.noData"
    >
      <template #emptyText>
        <div>
          <svg
            width="64"
            height="41"
            viewBox="0 0 64 41"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
              <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g fill-rule="nonzero" stroke="#d9d9d9">
                <path
                  d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                ></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#fafafa"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <p class="ant-empty-description">
          There is currently no Products data.
        </p>
        <p class="ant-empty-description">
          You can click the "Update" button above to try again.
        </p>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.name === 'action'">
          <div class="set-commission">
            <artmisads-select-button
              size="small"
              @click="() => onSetCommission(record.id)"
              >Set Commission</artmisads-select-button
            >
            <artmisads-button
              :isSquare="true"
              type="primary"
              v-if="record.status === 'inactive'"
              size="small"
              @click="() => getProductVariations(record, 'activate')"
              >Activate</artmisads-button
            >
            <artmisads-select-button
              v-else
              size="small"
              @click="() => getProductVariations(record, 'deactivate')"
              >Deactivate</artmisads-select-button
            >
          </div>
        </template>
        <template v-if="column.name === 'commission'">
          <span
            :class="
              'fw500 ' +
              (record.status === 'active'
                ? 'active-commission'
                : 'inactive-commission')
            "
            >{{ `${Number(record.commission).toFixed(1)}%` }}</span
          >
        </template>
        <template v-if="column.name === 'status'">
          <showStatus :isActive="record.status === 'active'" :noBorder="true" />
        </template>
        <template v-if="column.name === 'amazonName'">
          <div>
            <div  v-if="record.amazonName">{{record.amazonName}}</div>
            <div class="no-amazonName" v-else>--</div>
          </div>
        </template>
        <template v-if="column.name === 'deal'">
          <div>
            <product-deals-column
              v-if="record.productDeals?.length"
              v-for="item in record.productDeals"
              :record="item"
              :key="item.id"
            />
            <div class="no-deal" v-else>--</div>
          </div>
        </template>
        <template v-if="column.name === 'price'">
          <price :record="record" />
        </template>
      </template>
    </artmisads-table>
    <artmisads-modal
      v-model:open="showDeactivateModal"
      :title="`Are you sure you want to Deactivate this Product?`"
      :width="500"
      :onCancel="onCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="inactive-content">
        <p v-if="hasVariations">
          Deactivating a Product will archive the active Publisher/Creator links
          of the product and its related variations, and also make them
          unavailable to Publishers and Creators.
        </p>
        <p v-else>
          Deactivating a Product will archive all active Publisher/Creator
          links. and make this product unavailable to publishers and creators.
        </p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)"
          >Cancel</artmisads-button
        >
        <artmisads-button @click="confirmInactive" type="primary"
          >Confirm</artmisads-button
        >
      </template>
    </artmisads-modal>
    <artmisads-modal
      v-model:open="showActiveModal"
      :width="500"
      title="Are you sure you want to Activate this Product?"
      okText="Confirm"
      @ok="activateProduct"
      @cancel="setShowActivateModal(false)"
    >
      <div class="active-product">
        <div class="title-ext">
          Activate the Product, and both the product itself and its related
          variations will be activated.
        </div>
      </div>
    </artmisads-modal>
  </div>
</template>
<script lang="jsx" setup>
import { ref, watch } from 'vue';
import showStatus from '../Brands/components/showStatus.vue';
import productDealsColumn from '~/components/productDealsColumn.vue';
import { productsApi } from '@/server/index';

import Price from '~/components/Price.vue';

const showDeactivateModal = ref(false);
const tableRef = ref(null);
const props = defineProps([
  'listTotal', 'setParams', 'setShowSetCommission', 'setSelectedRowKeys', 'setSelectSingleProduct',
  'params', 'dataSource', 'loading', 'selectedRowKeys', 'onSelectChange', 'getProductList', 'onChangeActiveItems',
  'brandswithAvailableProducts','noData'
]);
const showActiveModal = ref(false);
const hasVariations = ref(false);
let currItem = {};

const confirmInactive = async () => {
  showDeactivateModal.value = false;
  await changeActive(currItem);
}

const setShowDeactivateModal = (status) => {
  showDeactivateModal.value = status;
}
const setShowActivateModal = (status) => {
  showActiveModal.value = status;
}

const columns = ref([
  {
    title: 'Product',
    dataIndex: 'title',
    width: 300,
    customRender: ({ text, record }) => {
      return (
        <a href={`/seller/product/${record.id}`} target="_blank" class="product-item">
          <img src={record.imageUrl} />
          <div class="text">
            <p class="title">{text}</p>
            <span class="asin">{record.asin}</span>
          </div>
        </a>
      )
    }
  },
  {
    title: 'Brand',
    dataIndex: 'brandName',
    width: 150,
  },
  {
    title: 'Amazon Seller Name',
    dataIndex: 'amazonName',
    name: 'amazonName',
    width: 195,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    width: 200,
  },
  {
    width: 100,
    title: 'Deal',
    name: 'deal',
    dataIndex: 'productDeals',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    name: 'price',
    width: 120,
  },
  {
    title: 'Commission',
    dataIndex: 'commission',
    name: 'commission',
    width: 118,
  },
  {
    title: 'Status',
    dataIndex: 'id',
    name: 'status',
    width: 108,
  },
  {
    name: 'availiability',
    title: 'Availiability',
    dataIndex: 'availiability',
    width: 114,
    customRender: ({ text, record }) => `${text == 'IN_STOCK' ? 'In Stock' : 'Out Of Stock'}`,

  },
  {
    name: 'action',
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right',
    width: 160,
  },
])

const onSetCommission = id => {
  props.setShowSetCommission(true);
  props.setSelectSingleProduct(id);
}

const onChangePage = data => {
  const { current, pageSize } = data;
  let newParams = { ...props.params };
  newParams.page = current;
  newParams.size = pageSize;
  props.setParams(newParams);
  props.getProductList(true);
}
const changeActive = async record => {
  let { status, id } = record;
  let params = [id];
  status = status === 'active' ? 'inactive' : 'active';
  props.onChangeActiveItems(status, params);
}
const activateProduct = async () => {
  setShowActivateModal(false);
  await changeActive(currItem);
}
const getProductVariations = async (record, status) => {
  currItem = record;
  let { id } = currItem;
  let hasVariation = false;
  let res = await productsApi.getProductVariations([id]);
  if (res.data.activeProductVariationCount || res.data.inactiveProductVariationCount) {
    hasVariation = true;
  }
  if (status == 'activate') {
    if (hasVariation) {
      setShowActivateModal(true);
    } else {
      changeActive(record);
    }
  }
  if (status == 'deactivate') {
    if (hasVariation) {
      hasVariations.value = true;
    } else {
      hasVariations.value = false;
    }
    setShowDeactivateModal(true);
  }
}

watch(() => props.dataSource, data => {
  let hasPromoCode = false
  let hasCombineDeal = false
  let hasProductDeal = false

  for (let product of data) {
    if (product.combineDeal) {
      hasCombineDeal = true
    }

    if (product.productDeals?.length) {
      hasProductDeal = true

      for (let deal of product.productDeals) {
        if (deal.promoCode) {
          hasPromoCode = true
          break
        }
      }
    }

    if (hasCombineDeal.value && hasPromoCode.value) {
      break
    }
  }

  if (hasCombineDeal) {
    columns.value[5]['width'] = 180
  } else {
    columns.value[5]['width'] = 120
  }

  if (hasPromoCode) {
    columns.value[0]['width'] = 320
    columns.value[4]['width'] = 300
  } else if (hasProductDeal) {
    columns.value[0]['width'] = 360
    columns.value[4]['width'] = 170
  } else {
    columns.value[0]['width'] = 400
    columns.value[4]['width'] = 100
  }
})
</script>

<style lang="less" scoped>
:deep(.ant-table) {
  .ant-table-cell {
    padding: 16px 8px;
  }

  .set-commission {
    > button {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  table {
    table-layout: fixed !important;
  }

  .inactive-commission {
    color: var(--dark-4);
  }

  .status-text {
    text-transform: capitalize;
  }

  .product-item {
    display: flex;

    img {
      width: 56px;
      height: 56px;
      margin-right: 12px;
      // border: 1px solid var( --border-1);
      border-radius: 8px;
      object-fit: contain;
    }

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;

      .title {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--dark-1);
        font-weight: 500;
        font-size: var(--font-size-middle);
        text-decoration: underline;
      }

      .asin {
        color: var(--dark-3);
        font-weight: 500;
        font-size: var(--font-size-min);
        letter-spacing: 0.3px;
        text-align: right;
        line-height: 16px;
        text-align: left;
        margin-top: 2px;
      }
    }
  }
  .no-amazonName,
  .no-deal {
    color: var(--dark-3);
  }
}
</style>