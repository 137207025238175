<template>
  <div class="filter" ref="filterRef">
    <a-popover v-model:open="visible" :getPopupContainer="() => filterRef" placement="bottomLeft" destroyTooltipOnHide="true" trigger="click">
      <template #content>
        <div class="content"  ref="filterContentRef">
          <div class="select-item">
            <span>Amazon Seller Name</span>
            <artmisads-select
              v-model:value="defaultFilter.amazonUserId"
              :getPopupContainer="() => filterContentRef"
              :options="amazonNameListAll"
            />
          </div>
          <div v-if="brandswithAvailableProductsAll" class="select-item">
            <span>Brand</span>
            <artmisads-select
              v-model:value="defaultFilter.brandId"
              :getPopupContainer="() => filterContentRef"
              :options="brandswithAvailableProductsAll"
            />
          </div>
          <div class="select-item">
            <span>Status</span>
            <artmisads-select
              v-model:value="defaultFilter.status"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="active">Active</a-select-option>
              <a-select-option value="inactive">Inactive</a-select-option>
            </artmisads-select>
          </div>
          <div class="commission-min">
            <span class="commission-min-text">Commission Min</span>
            <artmisads-input-number
              v-model:value="defaultFilter.commission"
              :min="1"
              :max="100"
              :step="0.1"
              suffix='%'
              placeholder="Please enter..."
              @change="handleChange($event,'commission')"
            />
          </div>
          <div class="select-item">
            <span>Availability</span>
            <artmisads-select
              v-model:value="defaultFilter.availability"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK">In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK">Out of Stock</a-select-option>
            </artmisads-select>
          </div>
          <div class="select-item">
            <span>Price</span>
            <artmisads-select
              v-model:value="defaultFilter.price"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option :value="0">All</a-select-option>
              <a-select-option :value="1">Under {{currencySymbol}}10</a-select-option>
              <a-select-option :value="2">{{currencySymbol}}10 To {{currencySymbol}}25</a-select-option>
              <a-select-option :value="3">{{currencySymbol}}25 To {{currencySymbol}}50</a-select-option>
              <a-select-option :value="4">{{currencySymbol}}50 To {{currencySymbol}}100</a-select-option>
              <a-select-option :value="5">{{currencySymbol}}100 To {{currencySymbol}}200</a-select-option>
              <a-select-option :value="6">{{currencySymbol}}200 & Above</a-select-option>
            </artmisads-select>
          </div>
          <div class="select-item">
            <span>Deal</span>
            <artemisads-segmented v-model:value="defaultFilter.deal" :options="['Off', 'All', 'Active', 'Upcoming']" />
          </div>
          <div class="btn">
            <a
              @click="onReset"
              :class="'reset' + (filterNum ? '' : ' disabled')"
              href="javascript:;"
            >
              <span style="margin-right: 3px">Reset</span>
              <span class="filter-num" v-show="filterNum">{{ filterNum }}</span>
              <span style="margin-left: 3px">Filters</span>
            </a>
            <artmisads-button type="primary" size="middle" @click="onSave">Confirm</artmisads-button>
          </div>
        </div>
      </template>
      <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filterNum"
        showDownIcon="true"
      >Filters</artmisads-select-button>
    </a-popover>
  </div>
</template>
<script setup>
  import { onBeforeMount, watch, ref, computed, onMounted } from 'vue';
  import {CURRENCY_MAPPER} from '@/contants/common';
  import { useUserInfoStore } from '~/storeData/index';
  
  const store = useUserInfoStore();
  const commission  = ref('')
  const visible = ref(false);
  const filterRef = ref(null);
  const filterContentRef = ref(null);
  const props = defineProps([ 'setParams', 'params', 'getProductList', 'setSelectedRowKeys', 'brandswithAvailableProducts','amazonNameList' ]);
  const priceArr = [
    { maxPrice: '', minPrice: ''},
    { maxPrice: 10, minPrice: ''},
    { maxPrice: 25, minPrice: 10},
    { maxPrice: 50, minPrice: 25},
    { maxPrice: 100, minPrice: 50},
    { maxPrice: 200, minPrice: 100},
    { maxPrice: '', minPrice: 200},
  ]
  const currencySymbol = computed(() => {
    return CURRENCY_MAPPER[store.userInfo.countryCode].currency;
  });
  const filterNum = computed(() => {
    let num = 0;
    if (defaultFilter.value.status !== 'all') {
      num += 1;
    }
    if (defaultFilter.value.availability !== 'all') {
      num += 1;
    }
    if (defaultFilter.value.commission) {
      num += 1;
    }
    if (defaultFilter.value.price) {
      num += 1;
    }
    if (defaultFilter.value.deal !== 'Off') {
      num += 1;
    }
    if (defaultFilter.value.brandId !== 'all') {
      num += 1;
    }
    if(defaultFilter.value.amazonUserId !== 'all'){
      num +=1
    }
    return num;
  })

  const handleChange = (e,itemName) => {
    defaultFilter.value.commission = e;
  }

  const defaultFilter = ref({
    status: 'all',
    availability: 'all',
    commission: '',
    price: 0,
    deal: 'Off',
    brandId: 'all',
    amazonUserId:'all'
  })

  const amazonNameListAll = computed(() => {
    let arrList = []
    if (props.amazonNameList.length > 0) {
      arrList = [{
        label: 'All', value: 'all'
      }, ...props.amazonNameList];
    }
    return arrList;
  })

  const brandswithAvailableProductsAll = computed(() => {
    let arrList = []
    if (props.brandswithAvailableProducts.length > 0) {
      arrList = [{
        label: 'All', value: 'all'
      }, ...props.brandswithAvailableProducts];
    }
    return arrList;
  })

  const onReset = () => {
    defaultFilter.value = {
      status: 'all',
      availability: 'all',
      commission: '',
      price: 0,
      deal: 'Off',
      brandId: 'all',
      amazonUserId:'all'
    };
    props.setParams({
      availability: '',
      commission: '',
      maxPrice: '',
      minPrice: '',
      page: 1,
      size: 20,
      sortType: 1,
      status: '',
      title: '',
      brandId: ''
    });

    props.setSelectedRowKeys([]);
    props.getProductList();
    visible.value = false;
  }

  const onSave = () => {
    let newParams = { ...props.params };
    let priceItem = priceArr[defaultFilter.value.price];
    newParams.maxPrice = priceItem.maxPrice;
    newParams.minPrice = priceItem.minPrice;
    newParams.deal =  defaultFilter.value.deal;
    newParams.status = defaultFilter.value.status === 'all' ? '' : defaultFilter.value.status;
    newParams.availability = defaultFilter.value.availability === 'all' ? '' : defaultFilter.value.availability;
    newParams.commission =  defaultFilter.value.commission;
    newParams.brandId =  defaultFilter.value.brandId === 'all' ? '' : defaultFilter.value.brandId;
    newParams.amazonUserId =  defaultFilter.value.amazonUserId === 'all' ? '' : defaultFilter.value.amazonUserId;
    newParams.page =  1;
    props.setParams(newParams);
    props.setSelectedRowKeys([]);
    props.getProductList();
    visible.value = false;
  }

  // watch(() => defaultFilter.value.commission, (newValue) => {
  //   showDefalutText.value = !newValue;
  // })

  onMounted(() => {
    const timer = setTimeout(() => {
      const priceArrIndex = priceArr.findIndex(item => (
        item.maxPrice === props.params.maxPrice && item.minPrice === props.params.minPrice
      ))
      defaultFilter.value.price = priceArrIndex;
      defaultFilter.value.deal = props.params.deal || 'Off';
      defaultFilter.value.status = props.params.status || 'all';
      defaultFilter.value.commission = props.params.commission;
      defaultFilter.value.brandId = props.params.brandId || 'all';
      defaultFilter.value.availability = props.params.availability || 'all';
      clearTimeout(timer);
    }, 1000)
  })


</script>
<style lang="less" scoped>
  .filter {
    .content {
      padding-top: 16px;
      .save-btn {
        display: flex;
        margin: 10px 16px 10px 0;
        justify-content: right;
      }
      .btn {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--border-4);
        padding: 8px 16px;
        margin-top: 32px;
        .reset {
          line-height: 32px;
          color: var(--primary-text);
          &.disabled {
            color: var(--dark-4);
          }
          .filter-num {
            font-weight: 700;
          }
        }
        button {
          padding: 0 16px;
          border-radius: 16px;
          min-width: 88px !important;
        }
      }
      .commission-min, .select-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .select-item {
        > span {
          margin-bottom: 4px;
          font-weight: 500;
          font-size: var(--font-size-small);
          color: var(--dark-2);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
      }
      .commission-min {
        position: relative;
        .commission-min-text {
          margin-bottom: 4px;
          color: var(--dark-2);
          font-weight: 500;
          font-size: var(--font-size-small);
          line-height: 18px;
        }
      }
      :deep(.ant-segmented-group) {
        justify-content: space-between;
      }
      :deep(.ant-select) {
        width: 248px;
      }
      :deep(.ant-input-number) {
        width: 248px !important;
      }
    }
  }
  
</style>
