<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-13 15:54:00
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-30 14:51:29
 * @FilePath: /web_artemisads/src/pages/seller/product/fileBar/productDealsResult.vue
 * @Description: 
-->
<template>
    <div class="match-table">
      <div class="success">
        <p>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-success-tianchong"></use>
          </svg>
          <span>
            {{props.dataList.successList.length}} Successful matches
          </span>
        </p>
        <div class="border-radius">
          <artmisads-table
            bordered
            :columns="columnsSuccess"
            row-key="id"
            :change="onChangeSuccessPage"
            rowClassName="table-item"
            :data-source="props.dataList.successList"
            :scroll="{ y: 465, x: 1500 }"
            :pagination="{
              current: successPage,
              total: props.dataList.successList.length,
              defaultPageSize: 10,
            }"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name === 'commission'">
                <span :class="record.status === 'active' ? 'active-commission' : 'inactive-commission'">{{`${Number(record.commission).toFixed(1)}%`}}</span>
              </template>
              <template v-if="column.name === 'beforeStatus'">
                <showStatus :isActive="record.beforeStatus === 'active'" :noBorder="true"/>
              </template>
              <template v-if="column.name === 'afterStatus'">
                <showStatus :isActive="record.afterStatus === 'active'" :noBorder="true"/>
              </template>
            </template>
          </artmisads-table>
        </div>
      </div>
      <div class="operation-btn">
        <artmisads-button @click="onBack">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-zuo-cu"></use>
          </svg>
          Back
        </artmisads-button>
        <div class="update">
          <!-- <artmisads-button @click="onCancel">Cancel</artmisads-button> -->
          <artmisads-button type="dashed" @click="onCancel">Cancel</artmisads-button>
          <artmisads-button v-if="props.dataList.successList.length" type="primary" @click="onUpdate">Update</artmisads-button>
          <artmisads-button v-else disabled>Update</artmisads-button>
        </div>
        
      </div>
      <div class="fail" v-if="props.dataList.failList.length">
        <p>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-error-tianchong5"></use>
          </svg>
          <span>{{props.dataList.failList.length}} Failed matches</span>
        </p>
        <div class="border-radius">
          <artmisads-table
            bordered
            :columns="columnsFail"
            row-key="id"
            rowClassName="table-item"
            :data-source="props.dataList.failList"
            :change="onChangeFailPage"
            :scroll="{ y: 465, x: 1500 }"
            :pagination="{
              total: props.dataList.failList.length,
              defaultPageSize: 10,
              current: failPage,
            }"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name === 'commission'">
                <span :class="record.status === 'active' ? 'active-commission' : 'inactive-commission'">{{`${Number(record.commission).toFixed(1)}%`}}</span>
              </template>
              <template v-if="column.name === 'status'">
                <showStatus :isActive="record.status === 'active'" :noBorder="true"/>
              </template>
            </template>
          </artmisads-table>
        </div>
      </div>
    </div>
  </template>
  <script lang="jsx" setup>
    import { message } from 'ant-design-vue';
    import { ref } from 'vue';
  
    import showStatus from '../../Brands/components/showStatus.vue';
    import { productsApi } from '../../../../server/index'

    import moment from 'moment';
  
    const successPage = ref(1);
    const failPage = ref(1);
  
    const props = defineProps([
      'dataList', 'setDataList', 'setShowExample', 'setShowUpdateProductModal', 'getProductList', 'onCancel'
    ]);
  
    const onChangeSuccessPage = ({ current }) => {
      successPage.value = current;
    }
  
    const onChangeFailPage = ({ current }) => {
      failPage.value = current;
    }
  
    const onUpdate = async () => {
      let params = props.dataList.successList;
      let res = await productsApi.doUpdateProductDealsCsv({deals : params});
      const { success, error } = res;
      if (success) {
        message.success('Success');
        props.getProductList();
        props.onCancel();
      }
    }
  
    const onBack = () => {
      props.setDataList({
        failList: [],
        successList: []
      });
      props.setShowExample(true);
    }
  
    const columnsFail = ref([
      {
        title: 'ASIN',
        dataIndex: 'asin',
      },
      {
        title: 'Reasons for Failure',
        dataIndex: 'failedReason',
        width: 230,
      },
      {
        title: 'Amazon Seller Name',
        dataIndex: 'amazonSellerAccount',
        width: 230,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        width: 120,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        width: 120,
      },
      {
        title: 'Full Price',
        dataIndex: 'fullPrice',
      },
      {
        title: 'Final Price',
        dataIndex: 'finalPrice',
      },
      {
        title: 'Deal Type',
        dataIndex: 'dealType',
        width: 180
      },
      {
        title: 'Promo Code',
        dataIndex: 'promoCode',
        width: 130
      }
    ])
  
    const columnsSuccess = ref([
      {
        title: 'Product',
        dataIndex: 'productTitle',
        width: '30%',
        customRender: ({ text, record }) => {
          return (
            <div class="product-item">
              <img src={record.productImageUrl}/>
              <div class="text">
                <span class="title">{text}</span>
                <span class="asin">{record.asin}</span>
              </div>
            </div>
          )
        }
      },
      {
        title: 'Amazon Seller Name',
        dataIndex: 'amazonSellerAccount',
        width: 230,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        width: 120,
        customRender: ({ text, record }) => {
          return moment(text).format('YYYY/MM/DD HH:mm:ss')
        }
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        width: 120,
        customRender: ({ text, record }) => {
          return moment(text).format('YYYY/MM/DD HH:mm:ss')
        }
      },
      {
        title: 'Full Price',
        dataIndex: 'fullPrice',
      },
      {
        title: 'Final Price',
        dataIndex: 'finalPrice',
      },
      {
        title: 'Deal Type',
        dataIndex: 'dealType',
        width: 180
      },
      {
        title: 'Promo Code',
        dataIndex: 'promoCode',
        width: 130
      }
    ])
  
  </script>
  
  <style lang="less" scoped>
    .match-table {
      .success,
      .fail {
        > p {
          display: flex;
          font-size: 16px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-bottom: 12px;
          font-weight: 500;
          svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
          > span {
            align-self: center;
          }
        }
        > .border-radius {
          overflow: hidden;
          border-radius: 8px;
        }
        :deep(.ant) {
          &-table-wrapper .ant-table-cell {
            padding: 8px;
            line-height: 20px;
            word-break: break-word;
            border-color: var(--border-4) !important;
          }
        }
      }
      
      .success {
        padding: 8px 0 2px;
        > p {
          :deep(svg) {
            fill: var(--green-3);
          }
        }
      }
      .fail {
        margin: 0 -24px -16px;
        border-radius: 0 0 8px 8px;
        padding: 24px 24px 8px 24px;
        background-color: var(--bg-gray-1);
        border-top: 1px solid var(--border-4);
        > p {
          :deep(svg) {
            fill: var(--red-1);
          }
        }
      }
      .operation-btn {
        display: flex;
        padding-bottom: 24px;
        justify-content: space-between;
        > button {
          display: flex;
          svg {
            align-self: center;
            width: 18px;
            height: 18px;
            fill: var(--dark-2);
            margin-right: 8px;
          }
          :deep(span) {
            align-self: center;
          }
        }
        .update {
          > button {
            margin-left: 8px;
          }
        }
      }
      :deep(.ant-table) {
        .inactive-commission {
          color: var(--dark-4);
        }
        .status-text {
          text-transform: capitalize;
        }
        .product-item {
          display: flex;
          img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            object-fit: contain;
          }
          .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            justify-content: center;
            .title {
              max-width: 300px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .asin {
              margin-top: 4px;
            }
          }
        }
      }
    }
    
  </style>